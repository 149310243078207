import React from 'react'

import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap';


function Footer() {

  const thisYear = new Date().getFullYear();
  return (
  <div className="footer-container">
    <Container className="footer-content">
      <Row>
        <Col xs={12} md={6} className="footer-info-container">
          <h3>Contact Informatie</h3>
          <Row>
            <Col className="footer-info-left">
              <span>Email:</span>
              <span>Facebook:</span>
              <span>Instagram:</span>
            </Col>
            <Col className="footer-info-right">
              <span><a href="mailto:ensemble@duo-juna.nl">ensemble@duo-juna.nl</a></span>
              <span><a href="https://www.facebook.com/Duojuna" target="_blank">facebook.com/duojuna</a></span>
              <span><a href="https://www.instagram.com/duojuna/">instagram.com/duojuna</a></span>
            </Col>
          </Row>
        
        </Col>
        <Col xs={12} md={6} className="footer-link-container">
          <h3>Navigatie</h3>
            <Row className="link-container">
              <Col activeClassName="navbar-active">
                <Link to="/" className="footer-nav-link" activeClassName="navbar-active">Home</Link>
                <Link to="/over-ons" className="footer-nav-link" activeClassName="navbar-active">Over Ons</Link>
                <Link to="/agenda" className="footer-nav-link" activeClassName="navbar-active">Agenda</Link>
              </Col>
              <Col  activeClassName="navbar-active">
                <Link to="/gallerij" className="footer-nav-link" activeClassName="navbar-active">Gallerij</Link>
                <Link to="/contact" className="footer-nav-link" activeClassName="navbar-active">Contact</Link>
              </Col>
            </Row>
        </Col>
      </Row>
      <Row className="copyright">
        <Col xs={6} >
          <p>© Duo Juna {thisYear}</p>
        </Col>
        <Col>
          <a href="https://www.pepperstudio.nl" target="_blank">Webdesign: Pepperstudio</a>
        </Col>
      </Row>
    </Container>
  </div>
  )
}

export default Footer

{/* <Row>
<Col xs={12}>
 <p>© Duo Juna {thisYear}</p>
</Col>

<Col xs={12}>
  <p>KVK: 12345678</p>
</Col>

<Col xs={12}>
 <a href="https://www.pepperstudio.nl" target="_blank">Webdesign: Pepperstudio</a>
</Col>

</Row> */}
