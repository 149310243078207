import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link } from "gatsby"

function Burger({ menuItems }) {

  const showSettings = (event) => {
    event.preventDefault();
  }
  
  return (
    <Menu right style="burger-menu">
        {menuItems.map((item) => {
          return <Link to={item.link}>{item.title}</Link>
        })}
    </Menu>
  )
}

export default Burger
