export const menuItems = [
  { title: "Home",
    link: "/" 
  },
  {
    title: "Over ons",
    link: "/over-ons"
  },
  {
    title: "Mogelijkheden",
    link: "/mogelijkheden"
  },
  {
    title: "Agenda",
    link: "/agenda"
  },
  {
    title: "Galerij",
    link: "/galerij"
  },
  {
    title: "Contact",
    link: "/contact"
  }]